import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { confirmAlert } from 'react-confirm-alert'
import { withRouter } from '../../components/funcs/BASES/withRouter'
import endPoints from '../../components/Consts/endPoints.json'
import notifyTargets from '../../components/Consts/notifyTargets.json'
import notificationTypes from '../../components/Consts/notificationTypes.json'
import { getTranslatation } from '../../locales/translate'

import { FaSave, FaFolder, FaEnvelope } from 'react-icons/fa'
import { FormLayout, MainLayout } from '../../components/features/Layouts'
import { InputOne } from '../../components/lib/input'
import { SelectOne } from '../../components/lib/select'
import { GridBlock } from '../../components/lib/grid'
import Grid from '@material-ui/core/Grid'

import * as ChallangeActions from '../../redux/actions/Challange'
import * as AlldetailsActions from '../../redux/actions/Alldetails'
import UploadOne from '../../components/lib/upload/UploadOne'


import * as ProgramActions from '../../redux/actions/Program'



var curr = new Date();
curr.setDate(curr.getDate() + 1);
var date = curr.toISOString().substring(0, 10);

var curr2 = new Date();
curr2.setDate(curr2.getDate() + 30);
var date2 = curr2.toISOString().substring(0, 10);

// ** 1- Aktif Bir Programi olan kullanıcılar
// ** 2- Tüm Kullanıcılar
// ** 3- Hiç satın alım yapmamış kullanıcılar
// ** 4- Aktif Bir Programi olmayan fakat herhangi bir programı daha oncee satın almıs olan kullanıcılar
//************************************************************** */
// ** 5- aynı gun antrenmanı olanlar
// ** 6- aynı gun antrenmanını tamamlamayanlar
// ** 7- su akdar gun gecmiste dukadar antrenman yapmayanlar
// ** 8- su akdar gun gecmiste dukadar antrenman yapanlara
class ChallangesForm extends Component {
  constructor(props) {
    super(props)
    this.state = {

      onProcess: false,
      activeSection: 'form', // files

      id: '',
      program_ID: '',
      title: '',
      isActive: 0,
      description: '',
      chatUrl: '',

      startDate: date,
      endDate: date2,

      programOptions: []

    }
    this.releatedRoutes = endPoints.find(aa => aa.name == 'Challanges')?.routes;
    this.ref_fileArea1 = React.createRef()
    this.ref_fileArea2 = React.createRef()
  }

  componentDidMount() {
    this.props.actions.GetAllDetails(
      null,
      () => { },
      () => { },
      () => { },
      () => { }
    )


    this.props.actions.GetAllPrograms(
      null,
      () => { },
      () => { },
      (data) => {
        const programOptions = data.filter(aa => aa.price > 0).map((item) => {
          return { value: item.program_id, text: item.programName }
        })
        this.setState({ programOptions })
      },
      () => { }
    )

    try {
      var id = this.props.router.location.state.id
      this.setState({ id: id }, () => {


        this.props.actions.GetOne(
          this.state.id,
          () => {
            this.setState({ onProcess: true })
          },
          () => {
            setTimeout(
              function () {
                this.setState({ onProcess: false })
              }.bind(this),
              500
            )
          },
          data => {

            this.setPageForGETID(data)
          },
          () => { }
        )

      })
    } catch (err) { }
  }

  setPageForGETID = data => {
    console.log(data)
    this.setState({
      program_ID: data?.program_ID,
      title: data?.title,
      description: data?.description,
      chatUrl: data?.chatUrl,
      isActive: data?.isActive,
    }, () => {
      this.setState({
        startDate: data?.startDate.substring(0, 10),
        endDate: data?.endDate.substring(0, 10),
      }, () => {
        this.ref_fileArea1.current.state.files = data?.files != null ? data?.files.filter(file => file._mimetype.includes('image')) : [];
        this.ref_fileArea2.current.state.files = data?.files != null ? data?.files.filter(file => file._mimetype.includes('video')) : [];
      })
    })
  }

  submit = () => {
    var id = this.props.router?.location?.state?.id
    if (id == null || id == '') {
      // CREATE
      this.props.actions.Create(
        {
          program_ID: this.state.program_ID,
          title: this.state?.title,
          description: this.state?.description,
          isActive: this.state?.isActive,
          startDate: this.state?.startDate,
          endDate: this.state?.endDate,
          chatUrl: this.state?.chatUrl,
        },
        () => {
          this.setState({ onProcess: true })
        },
        () => {
          setTimeout(() => {
            this.setState({ onProcess: false })
          }, 500)
        },
        () => {
          this.props.router.navigate(this.releatedRoutes.get)
        },
        () => { }
      )
    } else {
      // UPDATE
      this.props.actions.Update(
        {
          id: this.state?.id,
          program_ID: this.state.program_ID,
          title: this.state?.title,
          description: this.state?.description,
          isActive: this.state?.isActive,
          startDate: this.state?.startDate,
          endDate: this.state?.endDate,
          chatUrl: this.state?.chatUrl,
        },
        () => {
          this.setState({ onProcess: true })
        },
        () => {
          setTimeout(() => {
            this.setState({ onProcess: false })
          }, 500)
        },
        () => {
          this.props.router.navigate(this.releatedRoutes.get)
        },
        () => { }
      )
    }
  }

  setVal = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {

    return (
      <FormLayout
        naviIcon={<FaEnvelope size={30} color={'white'} />}
        recordIcon={<FaSave size={30} color={'white'} />}
        recordAction={this.submit}
        filesIcon={
          (this.state.id != null && this.state.id != '') &&
          <div onClick={() => { this.setState({ activeSection: this.state.activeSection == 'form' ? 'files' : 'form' }) }} >
            <FaFolder size={30} color={'white'} />
          </div>}
        activeBack={() => {
          this.props.router.navigate(this.releatedRoutes?.get)
        }}
        loading={this.state.onProcess}
      >

        <GridBlock
          style={{
            display: this.state.activeSection == 'files' ? 'block' : 'none'
          }} container={true} xs={12} sm={12} lg={12}>


          <GridBlock
            title='Dosyalar' container={true} xs={12} sm={12} lg={12}>


            <UploadOne
              ref={this.ref_fileArea1}
              innerRef={this.ref_fileArea1}
              cascadeID={this.state.id}
              showDropAreaWhenFull={true}
              formID={12} // challange image 1 adet
              allowedLength={6}
              xs={12}
              sm={12}
              lg={6}
              _xs={12}
              _sm={12}
              _lg={12}
              _mainContainer
              acceptFiles={'image/jpeg, image/png, image/jpg'}
              dropLabel={
                <div>
                  Challange resmi yükleyiniz <br /> en fazla 6 Adet{' '}
                </div>
              }
            />

            <UploadOne
              ref={this.ref_fileArea2}
              innerRef={this.ref_fileArea2}
              cascadeID={this.state.id}
              showDropAreaWhenFull={true}
              formID={12} // challange image 1 adet
              allowedLength={1}
              xs={12}
              sm={12}
              lg={6}
              _xs={12}
              _sm={12}
              _lg={12}
              _mainContainer
              acceptFiles={'video/mp4'}
              dropLabel={
                <div>
                  Challange videosu yükleyiniz <br /> en fazla 1 Adet{' '}
                </div>
              }
            />
          </GridBlock>
        </GridBlock>

        <GridBlock
          style={{
            display: this.state.activeSection == 'form' ? 'block' : 'none'
          }} container={true} xs={12} sm={12} lg={12}>
          <GridBlock
            title={endPoints.find(aa => aa.name == 'Challanges')?.menutext}
            container={true}
            xs={12}
            sm={12}
            lg={12}
          >


            <InputOne
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={12}
              lg={8}
              id='title'
              label={getTranslatation('title', 'CHALLANGES')}
              name='title'
              value={this.state.title}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('titleHelper', 'CHALLANGES')}
            />


            <SelectOne
              placeholder={getTranslatation('isActive', 'CHALLANGES')}
              label={getTranslatation('isActive', 'CHALLANGES')}
              helperText={getTranslatation('isActiveHelper', 'CHALLANGES')}
              xs={12}
              sm={12}
              lg={4}
              value={this.state.isActive}
              onChange={this.setVal}
              name='isActive'
              id='isActive'
              options={[
                { value: 1, text: 'Aktif' },
                { value: 0, text: 'Pasif' },
              ]}
            />


            <InputOne
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={12}
              lg={8}
              id='chatUrl'
              label={getTranslatation('chatUrl', 'CHALLANGES')}
              name='chatUrl'
              value={this.state.chatUrl}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('chatUrlHelper', 'CHALLANGES')}
            />

            <SelectOne
              placeholder={getTranslatation('program_ID', 'CHALLANGES')}
              label={getTranslatation('program_ID', 'CHALLANGES')}
              helperText={getTranslatation('program_IDHelper', 'CHALLANGES')}
              xs={12}
              sm={12}
              lg={4}
              value={this.state.program_ID}
              onChange={this.setVal}
              name='program_ID'
              id='program_ID'
              options={this.state.programOptions}
            />


            <InputOne
              multiline={true}
              minRows={8}
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={12}
              lg={12}
              id='description'
              label={getTranslatation('description', 'CHALLANGES')}
              name='description'
              value={this.state.description}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('descriptionHelper', 'CHALLANGES')}
            />



            <InputOne
              type='date'
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={12}
              lg={6}
              id='startDate'
              label={getTranslatation('startDate', 'CHALLANGES')}
              name='startDate'
              value={this.state.startDate}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('startDateHelper', 'CHALLANGES')}
            />



            <InputOne
              type='date'
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={12}
              lg={6}
              id='endDate'
              label={getTranslatation('endDate', 'CHALLANGES')}
              name='endDate'
              value={this.state.endDate}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('endDateHelper', 'CHALLANGES')}
            />
          </GridBlock>
        </GridBlock>
      </FormLayout>
    )
  }
}

//connect componenet with redux store
function mapStateToProps(state) {
  return {
    AlldetailsReducers: state.AlldetailsReducers
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      GetOne: bindActionCreators(ChallangeActions.GetOne, dispatch),
      Create: bindActionCreators(ChallangeActions.Create, dispatch),
      Update: bindActionCreators(ChallangeActions.Update, dispatch),

      GetAllDetails: bindActionCreators(
        AlldetailsActions.GetAllDetails,
        dispatch
      ),

      GetAllPrograms: bindActionCreators(ProgramActions.GetAll, dispatch),


      // Delete: bindActionCreators(UsersActions.Delete, dispatch)
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChallangesForm)
)
