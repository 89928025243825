import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { confirmAlert } from 'react-confirm-alert'
import { withRouter } from '../../components/funcs/BASES/withRouter'
import endPoints from '../../components/Consts/endPoints.json'
import { getTranslatation } from '../../locales/translate'

import { FaSave, FaFolder, FaShapes } from 'react-icons/fa'
import { FormLayout, MainLayout } from '../../components/features/Layouts'
import { InputOne } from '../../components/lib/input'
import { SelectOne } from '../../components/lib/select'
import { GridBlock } from '../../components/lib/grid'
import Grid from '@material-ui/core/Grid'
import UploadOne from '../../components/lib/upload/UploadOne'

import { Antrenmanlar, Ekipmanlar, Degisimler, SoruCevap } from '../../components/features/Programs'

import * as ProgramActions from '../../redux/actions/Program'
import * as AlldetailsActions from '../../redux/actions/Alldetails'

class ProgramsForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      onProcess: false,
      activeSection: 'form', // files

      program_id: '',

      programName: '',
      programDesc: '',
      price: '',
      fitnessLevelID: '',

      beslenmeDahilmi: 0,
      hedefBolge: '',
      kacGun: '',
      programKazanc: '',

      programCategories: [],
      antrenmanlar: [],

      ekipmanlar: [],
      soruCevap: [],
      degisimler: [],

      iosPaymentID: '',
      androidPaymentID: '',
    }
    this.releatedRoutes = endPoints.find(aa => aa.name == 'Programs')?.routes
    this.ref_fileArea1 = React.createRef()
    this.ref_fileArea2 = React.createRef()
  }

  componentDidMount() {
    this.props.actions.GetAllDetails(
      null,
      () => { },
      () => { },
      () => { },
      () => { }
    )
    try {
      var id = this.props.router.location.state.id
      this.setState({ program_id: id }, () => {
        this.props.actions.GetOne(
          this.state.program_id,
          () => {
            this.setState({ onProcess: true })
          },
          () => {
            setTimeout(
              function () {
                this.setState({ onProcess: false })
              }.bind(this),
              500
            )
          },
          data => {
            this.setPageForGETID(data)
          },
          () => { }
        )
      })
    } catch (err) { }
  }

  setPageForGETID = data => {
    console.log(data, 'ssssssssssssss')
    this.setState({
      programName: data?.programName,
      programDesc: data?.programDesc,
      price: data?.price,
      fitnessLevelID: data?.fitnessLevelID,

      beslenmeDahilmi: data?.beslenmeDahilmi,
      hedefBolge: data?.hedefBolge,
      kacGun: data?.kacGun,
      programKazanc: data?.programKazanc,

      programCategories: data?.categories,
      antrenmanlar: data?.antrenmanlar,

      ekipmanlar: data?.ekipmanlar,
      soruCevap: data?.soruCevap,
      degisimler: data?.degisimler,

      iosPaymentID: data?.iosPaymentID,
      androidPaymentID: data?.androidPaymentID,
    },
      () => {
        this.ref_fileArea1.current.state.files = data?.medias != null ? data?.medias.filter(file => file._mimetype.includes('image')) : [];
        this.ref_fileArea2.current.state.files = data?.medias != null ? data?.medias.filter(file => file._mimetype.includes('video')) : [];
      }
    )
  }

  submit = () => {
    var id = this.props.router?.location?.state?.id
    if (id == null || id == '') {
      // CREATE
      this.props.actions.Create(
        this.state,
        () => {
          this.setState({ onProcess: true })
        },
        () => {
          setTimeout(() => {
            this.setState({ onProcess: false })
          }, 500)
        },
        () => {
          this.props.router.navigate(this.releatedRoutes.get)
        },
        () => { }
      )
    } else {
      // UPDATE
      this.props.actions.Update(
        this.state,
        () => {
          this.setState({ onProcess: true })
        },
        () => {
          setTimeout(() => {
            this.setState({ onProcess: false })
          }, 500)
        },
        () => {
          this.props.router.navigate(this.releatedRoutes.get)
        },
        () => { }
      )
    }
  }

  setVal = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {

    return (
      <FormLayout
        naviIcon={<FaShapes size={30} color={'white'} />}
        recordIcon={<FaSave size={30} color={'white'} />}
        recordAction={this.submit}
        filesIcon={
          (this.state.program_id != null && this.state.program_id != '') &&
          <div onClick={() => { this.setState({ activeSection: this.state.activeSection == 'form' ? 'files' : 'form' }) }} >
            <FaFolder size={30} color={'white'} />
          </div>}
        activeBack={() => {
          this.props.router.navigate(this.releatedRoutes?.get)
        }}
        loading={this.state.onProcess}
      >








        <GridBlock
          style={{
            display: this.state.activeSection == 'files' ? 'block' : 'none'
          }} container={true} xs={12} sm={12} lg={12}>

          <GridBlock
            title='Dosyalar' container={true} xs={12} sm={12} lg={12}>

            <UploadOne
              ref={this.ref_fileArea1}
              innerRef={this.ref_fileArea1}
              cascadeID={this.state.program_id}
              showDropAreaWhenFull={true}
              formID={6}
              allowedLength={6}
              xs={12}
              sm={12}
              lg={6}
              _xs={12}
              _sm={12}
              _lg={12}
              _mainContainer
              acceptFiles={'image/jpeg, image/png, image/jpg'}
              dropLabel={
                <div>
                  Program resmi yükleyiniz <br /> en fazla 6 Adet{' '}
                </div>
              }
            />


            <UploadOne
              ref={this.ref_fileArea2}
              innerRef={this.ref_fileArea2}
              cascadeID={this.state.program_id}
              showDropAreaWhenFull={true}
              formID={6}
              allowedLength={1}
              xs={12}
              sm={12}
              lg={6}
              _xs={12}
              _sm={12}
              _lg={12}
              _mainContainer
              acceptFiles={'video/mp4'}
              dropLabel={
                <div>
                  Program videosu yükleyiniz <br /> en fazla 1 Adet{' '}
                </div>
              }
            />



          </GridBlock>
        </GridBlock>


        <GridBlock
          style={{
            display: this.state.activeSection == 'form' ? 'block' : 'none'
          }} container={true} xs={12} sm={12} lg={12}>
          <GridBlock
            title={endPoints.find(aa => aa.name == 'Programs')?.menutext}
            container={true}
            xs={12}
            sm={12}
            lg={12}
          >
            <InputOne
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={12}
              lg={6}
              id='programName'
              label={getTranslatation('programName', 'PROGRAMS')}
              name='programName'
              value={this.state.programName}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('programNameHelper', 'PROGRAMS')}
            />


            <SelectOne
              placeholder={getTranslatation('fitnessLevelID', 'PROGRAMS')}
              label={getTranslatation('fitnessLevelID', 'PROGRAMS')}
              helperText={getTranslatation('fitnessLevelIDHelper', 'PROGRAMS')}
              xs={12}
              sm={12}
              lg={6}
              value={this.state.fitnessLevelID}
              onChange={this.setVal}
              name='fitnessLevelID'
              id='fitnessLevelID'
              options={this.props.AlldetailsReducers?.AllDetails?.FitnessLevels}
            />



            <InputOne
              type={'number'}
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={12}
              lg={4}
              id='price'
              label={getTranslatation('price', 'PROGRAMS')}
              name='price'
              value={this.state.price}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('priceHelper', 'PROGRAMS')}
            />

            <InputOne
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={12}
              lg={4}
              id='iosPaymentID'
              label={getTranslatation('iosPaymentID', 'PROGRAMS')}
              name='iosPaymentID'
              value={this.state.iosPaymentID}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('iosPaymentIDHelper', 'PROGRAMS')}
            />


            <InputOne
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={12}
              lg={4}
              id='androidPaymentID'
              label={getTranslatation('androidPaymentID', 'PROGRAMS')}
              name='androidPaymentID'
              value={this.state.androidPaymentID}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('androidPaymentIDHelper', 'PROGRAMS')}
            />




            <InputOne
              multiline={true}
              minRows={4}
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={12}
              lg={12}
              id='programDesc'
              label={getTranslatation('programDesc', 'PROGRAMS')}
              name='programDesc'
              value={this.state.programDesc}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('programDescHelper', 'PROGRAMS')}
            />


            <SelectOne
              multiple={true}
              placeholder={getTranslatation('programCategories', 'PROGRAMS')}
              label={getTranslatation('programCategories', 'PROGRAMS')}
              helperText={getTranslatation('programCategoriesHelper', 'PROGRAMS')}
              xs={12}
              sm={6}
              lg={6}
              value={this.state.programCategories}
              onChange={this.setVal}
              name='programCategories'
              id='programCategories'
              options={this.props.AlldetailsReducers?.AllDetails?.ProgramCategory}
            />






            <SelectOne
              placeholder={getTranslatation('beslenmeDahilmi', 'PROGRAMS')}
              label={getTranslatation('beslenmeDahilmi', 'PROGRAMS')}
              helperText={getTranslatation('beslenmeDahilmi', 'PROGRAMS')}
              xs={12}
              sm={6}
              lg={6}
              value={this.state.beslenmeDahilmi}
              onChange={this.setVal}
              name='beslenmeDahilmi'
              id='beslenmeDahilmi'
              options={[
                { value: 1, text: 'Evet' },
                { value: 0, text: 'Hayır' },
              ]}
            />


            <InputOne
              multiline={false}
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={12}
              lg={6}
              id='hedefBolge'
              label={getTranslatation('hedefBolge', 'PROGRAMS')}
              name='hedefBolge'
              value={this.state.hedefBolge}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('hedefBolgeHelper', 'PROGRAMS')}
            />

            <InputOne
              multiline={false}
              type={'number'}
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={12}
              lg={6}
              id='kacGun'
              label={getTranslatation('kacGun', 'PROGRAMS')}
              name='kacGun'
              value={this.state.kacGun}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('kacGunHelper', 'PROGRAMS')}
            />


            <InputOne
              multiline={true}
              minRows={6}
              color={'success'}
              variant='filled'
              margin='none'
              required
              fullWidth
              xs={12}
              sm={12}
              lg={12}
              id='programKazanc'
              label={getTranslatation('programKazanc', 'PROGRAMS')}
              name='programKazanc'
              value={this.state.programKazanc}
              onChange={this.setVal}
              // error={this.state.mailOrPhone_ERROR.length > 0}
              helperText={getTranslatation('programKazancHelper', 'PROGRAMS')}
            />


            <GridBlock title='Belirlenmiş ekipmanlar' container={true} xs={12} sm={6} lg={4}>
              <Ekipmanlar ekipmanlar={this.state.ekipmanlar} fakeThis={this} />
            </GridBlock>

            <GridBlock title='Belirlenmiş degisimler' container={true} xs={12} sm={6} lg={8}>
              <Degisimler degisimler={this.state.degisimler} fakeThis={this} />
            </GridBlock>

            <GridBlock title='Soru Cevap Alanı' container={true} xs={12} sm={12} lg={12}>
              <SoruCevap soruCevap={this.state.soruCevap} fakeThis={this} />
            </GridBlock>


            <GridBlock title='Bağlı antrenmanlar' container={true} xs={12} sm={12} lg={12}>
              <Antrenmanlar antrenmanlar={this.state.antrenmanlar} fakeThis={this} />
            </GridBlock>

          </GridBlock>



        </GridBlock>
      </FormLayout>
    )
  }
}

//connect componenet with redux store
function mapStateToProps(state) {
  return {
    AlldetailsReducers: state.AlldetailsReducers
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      GetOne: bindActionCreators(ProgramActions.GetOne, dispatch),
      Create: bindActionCreators(ProgramActions.Create, dispatch),
      Update: bindActionCreators(ProgramActions.Update, dispatch),

      GetAllDetails: bindActionCreators(
        AlldetailsActions.GetAllDetails,
        dispatch
      )

      // Delete: bindActionCreators(UsersActions.Delete, dispatch)
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProgramsForm)
)
